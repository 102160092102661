import logo from "./logo.svg";
import "./App.css";
import QRContainer from "./Component/QRContainer";

function App() {
  return (
    <div className="App">
      <QRContainer />
    </div>
  );
}

export default App;
